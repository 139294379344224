import {attr, controller, target, targets} from '@github/catalyst'
import {ready} from '../document-ready'

type RunnerPlatform = 'linux-x64' | 'win-x64' | 'custom'
type RunnerImageSource = 'Curated' | 'Marketplace' | 'Custom'

@controller
class RunnerImageElement extends HTMLElement {
  @targets platforms: HTMLInputElement[]
  @targets imageVersions: RunnerImageVersionElement[]
  @target customImageUriInput: HTMLInputElement
  @target machineSpecsDropdown: MachineSpecsDropdownElement

  async connectedCallback() {
    // trigger 'selectRunnerPlatform' when page become ready to update visibility of machine specs elements
    await ready
    this.selectRunnerPlatform()

    // prevent switching tabs on arrow key press. By default, it happens because input element is located inside "tablist"
    // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tab_role#keyboard_interaction
    this.customImageUriInput.onkeydown = (e: Event) => e.stopPropagation()
  }

  selectRunnerPlatform() {
    const selectedPlatform = this.getSelectedPlatform()
    this.customImageUriInput.required = selectedPlatform === 'custom'

    if (this.machineSpecsDropdown) {
      const selectedImageVersion = this.getSelectedImageVersion(selectedPlatform)
      this.machineSpecsDropdown.updateOptionsVisibility(selectedPlatform, selectedImageVersion)
    }
  }

  getSelectedPlatform(): RunnerPlatform {
    const selectedPlatform = this.platforms.find(platform => platform.checked)
    if (selectedPlatform) {
      return selectedPlatform.value as RunnerPlatform
    }

    return 'linux-x64'
  }

  getSelectedImageVersion(selectedPlatform: RunnerPlatform): RunnerImageVersionElement {
    return (
      this.imageVersions.find(imageVer => imageVer.platform === selectedPlatform && imageVer.checked) ??
      this.imageVersions[0]
    )
  }
}

@controller
class MachineSpecsDropdownElement extends HTMLElement {
  @targets tabs: MachineSpecsTabElement[]
  @targets items: MachineSpecsItemElement[]
  @target tabsHeader: HTMLElement

  updateOptionsVisibility(platform: RunnerPlatform, imageVersion: RunnerImageVersionElement) {
    // refresh visibility of machine specs elements
    for (const item of this.items) {
      item.setVisibility(platform, imageVersion)
    }

    // only show tabs with visible items
    this.updateTabsVisibility()

    // if no item is selected or it's not visible, select the first visible item
    // the selected item might become hidden after the visibility refresh
    let selectedItem = this.getSelectedItem()
    if (!selectedItem || !selectedItem.visible) {
      const defaultItem = this.getFirstVisibleItem()
      defaultItem?.selectItem()
      selectedItem = defaultItem
    }

    // make sure the tab with selected item is selected
    if (selectedItem) {
      this.selectTabByType(selectedItem.type)
    }
  }

  private updateTabsVisibility() {
    let numberVisibleTabs = 0
    for (const tab of this.tabs) {
      const shouldTabBeVisible = this.items.some(item => item.type === tab.type && item.visible)
      numberVisibleTabs += shouldTabBeVisible ? 1 : 0
      tab.setVisibility(shouldTabBeVisible)
    }

    if (this.tabsHeader) {
      this.tabsHeader.hidden = numberVisibleTabs < 2
    }
  }

  private selectTabByType(type: string) {
    const targetTab = this.tabs.find(tab => tab.type === type)
    targetTab?.selectTab()
  }

  private getSelectedItem(): MachineSpecsItemElement | undefined {
    return this.items.find(item => item.checked)
  }

  private getFirstVisibleItem(): MachineSpecsItemElement | undefined {
    return this.items.find(item => item.visible)
  }
}

@controller
class MachineSpecsTabElement extends HTMLElement {
  @target clickArea: HTMLElement
  @attr type: string

  selectTab() {
    this.clickArea.click()
  }

  setVisibility(visible: boolean) {
    this.hidden = !visible
  }
}

@controller
class MachineSpecsItemElement extends HTMLElement {
  @target checkbox: HTMLInputElement
  @attr storageGb = 0
  @attr type: string

  get checked(): boolean {
    return this.checkbox.checked
  }

  get visible(): boolean {
    return !this.hidden
  }

  selectItem() {
    this.checkbox.click()
  }

  setVisibility(platform: RunnerPlatform, imageVersion: RunnerImageVersionElement) {
    this.hidden = !this.shouldBeVisible(platform, imageVersion)
  }

  private shouldBeVisible(platform: RunnerPlatform, imageVersion: RunnerImageVersionElement): boolean {
    if (platform === 'win-x64' || platform === 'linux-x64') {
      if (this.type === 'gpu_optimized' && imageVersion.source === 'Curated') {
        // Curated images don't play well with GPU SKUs so hide them
        return false
      }
    }

    // if SKU has less disk space than required by selected image, hide it
    if (this.storageGb < imageVersion.sizeGb) {
      return false
    }

    return true
  }
}

@controller
class RunnerImageVersionElement extends HTMLElement {
  @target checkbox: HTMLInputElement
  @attr sizeGb = 0
  @attr platform: RunnerPlatform
  @attr source: RunnerImageSource

  get checked(): boolean {
    return this.checkbox.checked
  }
}
