// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

function insightsFeatureSaveButton(): HTMLFormElement {
  return document.querySelector<HTMLFormElement>('.js-insights-feature-save-button')!
}

function insightsFeatureConfirmModal(): HTMLElement {
  return document.querySelector<HTMLElement>('.js-insights-feature-confirm-modal')!
}

on('click', '.js-insights-feature-checkbox', function (event) {
  const input = event.currentTarget as HTMLInputElement
  if (input.checked) {
    insightsFeatureSaveButton().hidden = false
    insightsFeatureConfirmModal().hidden = true
  } else {
    insightsFeatureSaveButton().hidden = true
    insightsFeatureConfirmModal().hidden = false
  }
})
